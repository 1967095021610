<template>
  <div class="popup_wrap" style="width:1000px;height:600px;">
    <!-- popup_wrap :  style="width:1000px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <form id="frm_eman">
      <button
        type="button"
        class="layer_close"
        @click="$emit('close')"
      >
        close
      </button>
      <div class="popup_cont">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.ONEX050P060.001') }}</h1><!-- SHA M/F Data -->
        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_col">
            <colgroup>
              <col width="8%">
              <col width="8%">
              <col width="12%">
              <col width="16%">
              <col width="14%">
              <col width="12%">
              <col width="12%">
              <col width="18%">
            </colgroup>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    class="tbl_icon plus"
                    @click="addEmanInfo()"
                  >
                    plus
                  </button>
                </th>
                <th>{{ $t('msg.ONEX050P060.002') }}</th><!-- No. -->
                <th>{{ $t('msg.ONEX050P060.003') }}</th><!-- Quantity -->
                <th>{{ $t('msg.ONEX050P060.004') }}</th><!-- PKG Code -->
                <th>{{ $t('msg.CSBK100.268') }}</th><!-- Gross Weight -->
                <th>{{ $t('msg.ONEX050P060.006') }}</th><!-- CBM -->
                <th>{{ $t('msg.ONEX050P060.007') }}</th><!-- UN No. -->
                <th>{{ $t('msg.ONEX050P060.008') }}</th><!-- Container No. -->
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(eman, idx) in params.emanList"
              >
                <tr :key="'tr_emanInfo_' + idx">
                  <th rowspan="2">
                    <button
                      type="button"
                      :class="['tbl_icon', 'minus', params.emanList.length === 1 ? 'disabled' : '']"
                      @click="removeEmanInfo(idx)"
                    >
                      minus
                    </button>
                  </th>
                  <td>{{ eman.seq }}</td>
                  <td>
                    <e-input-number
                      v-model="eman.pkgQty"
                      :id="'qty_' + idx"
                      :is-comma="true"
                      @input="fnValidationQuantity(eman.seq);fnSubTotal()"
                    />
                  </td>
                  <td>
                    <select
                      v-model="eman.pkgCd"
                      name=""
                      :id="'pkgCd_' + idx"
                      @change="fnValidationPkgCd(eman.seq)"
                    >
                      <option value="">{{ $t('msg.ONEX050P060.009') }}</option><!-- 선택 -->
                      <option
                        v-for="commonCode in pkgCdList"
                        :key="'01130_' + commonCode.cd"
                        :value="commonCode.cd"
                      >
                        {{ commonCode.cdNm }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <e-input-number
                      v-model="eman.netWt"
                      :id="'wt_' + idx"
                      :is-comma="true"
                      :point="3"
                      :digit-max="10"
                      @input="fnValidationWeight(eman.seq);fnSubTotal()"
                    />
                  </td>
                  <td>
                    <e-input-number
                      v-model="eman.netCbm"
                      :id="'cbm_' + idx"
                      :is-comma="true"
                      :point="4"
                      :digit-max="10"
                      @input="fnValidationCBM(eman.seq);fnSubTotal()"
                    />
                  </td>
                  <td>
                    <input
                      v-model="eman.imdunNo"
                      type="text"
                      maxlength="4"
                    >
                  </td>
                  <td rowspan="2">
                    <div :id="'cntr_no_' + idx">
                      <template
                        v-for="(cntr, idx2) in cntrList"
                      >
                        <input
                          :key="cntr.cntrNo + '_no_' + eman.seq + '_' + idx2"
                          type="checkbox"
                          :name="'conno_' + eman.seq"
                          :id="'conno_' + eman.seq + '_' + idx2"
                          :checked="eman.cntrNo.includes(cntr.cntrNo)"
                          :value="cntr.cntrNo"
                          @change="selectCntrNo(eman.seq);fnValidationContainer(eman.seq)"
                        />
                        <label
                          :key="cntr.cntrNo + '_lbl_' + eman.seq + '_' + idx2"
                          :for="'conno_' + eman.seq + '_' + idx2"
                        >{{ cntr.cntrNo }}</label>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr :key="'tr_emanInfo_cmdt_' + idx">
                  <th class="border_left" colspan="2">{{ $t('msg.ONEX050P060.010') }}</th><!-- ITEM(Commodity) -->
                  <td colspan="4">
                    <textarea
                      v-model="eman.gdsDsc"
                      style="min-height:50px;"
                      :id="'cmdt_' + idx"
                      @keyup="fnValidationCmdt(eman.seq)"
                      maxlength="500"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <table class="tbl_col mt10">
            <colgroup>
              <col width="16%">
              <col width="28%">
              <col width="28%">
              <col width="28%">
            </colgroup>
            <tbody>
              <tr>
                <th rowspan="2">{{ $t('msg.ONEX050P060.011') }}</th><!-- Total -->
                <th>{{ $t('msg.ONEX050P060.012') }}</th><!-- Quantity -->
                <th>{{ $t('msg.ONEX050P060.013') }}</th><!-- Weight -->
                <th>{{ $t('msg.ONEX050P060.014') }}</th><!-- CBM -->
              </tr>
              <tr>
                <td class="border_left">{{ params.sumQty }}</td>
                <td>{{ params.grsWt }}</td>
                <td>{{ params.grsCbm }}</td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->
        <div class="mt10 text_center">
          <a
            class="button blue lg"
            href="#"
            @click.prevent="fnEmanSave()"
          >{{ $t('msg.ONEX050P060.015') }}</a><!-- 저장 -->
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >{{ $t('msg.ONEX050P060.016') }}</a><!-- 취소 -->
        </div>
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import commons from '@/api/services/commons'
import shaMfData from '@/api/rest/trans/shaMfData'

export default {
  name: 'ShaMfDataPop',
  components: {
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          type: '', // SR or BL
          blNo: '',
          grsWt: 0,
          pkgQty: 0,
          grsCbm: 0,
          containerList: [],
          polCtrCd: ''
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      params: {
        type: '', // SR or BL
        blNo: '',
        emanList: [
          {
            seq: '1',
            pkgQty: '',
            pkgCd: '',
            netWt: '',
            netCbm: '',
            imdunNo: '',
            cntrNo: '',
            gdsDsc: '',
            mrkDtlSeq: '',
            mrkSeq: '',
            mrkDsc: '',
            selectedCntr: []
          }
        ],
        sumQty: 0,
        sumWt: 0,
        sumCbm: 0
      },
      cntrList: [],
      emanMaster: [],
      phbtList: [],
      pkgCdList: []
    }
  },
  created () {
    this.params = { ...this.params, ...this.parentInfo }

    this.initCommCodes()
    this.getShaMfDataList(this.params)
  },
  methods: {
    // 공통코드 호출(PACKAGE 단위)
    async initCommCodes () {
      const cdId = ['01130']
      this.commonCodes = await commons.getCommonsCodesCommon(cdId)
      const pkgCdList = this.commonCodes['01130']

      pkgCdList.sort((a, b) => {
        return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
      })

      this.pkgCdList = pkgCdList
    },
    async getShaMfDataList (params) {
      await shaMfData.getShaMfDataList(params)
        .then(response => {
          // console.log(response)
          const emanList = response.data.emanList
          const cntrList = response.data.cntrList
          const emanMaster = response.data.emanMaster
          const phbtList = response.data.phbtList

          if (emanList !== undefined && emanList.length > 0) {
            this.params.emanList = []
            emanList.forEach((eman, idx) => {
              eman.seq = idx + 1
              eman.selectedCntr = []

              if (eman.cntrNo !== undefined && eman.cntrNo !== '') {
                const cntrNoArr = eman.cntrNo.trim().split(',')
                cntrNoArr.forEach(cntrNo => {
                  eman.selectedCntr.push(cntrNo)
                })
              }

              this.params.emanList.push(eman)
            })
          }

          if (cntrList !== undefined && cntrList.length > 0) {
            this.cntrList = cntrList
          }

          if (emanMaster !== undefined && emanMaster.length > 0) {
            this.emanMaster = emanMaster
          }

          if (phbtList !== undefined && phbtList.length > 0) {
            this.phbtList = phbtList
          }

          this.fnSubTotal()

          // console.log('this.params.emanList')
          // console.log(this.params.emanList)

          // console.log('this.cntrList')
          // console.log(this.cntrList)

          // console.log('this.phbtList')
          // console.log(this.phbtList)
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 행 추가
    addEmanInfo () {
      const emanList = this.params.emanList
      const listLen = emanList.length
      const seq = Number(emanList[listLen - 1].seq) + 1

      emanList.push(
        {
          seq: seq,
          pkgQty: '',
          pkgCd: '',
          netWt: '',
          netCbm: '',
          imdunNo: '',
          cntrNo: '',
          gdsDsc: '',
          mrkDtlSeq: '',
          mrkSeq: '',
          mrkDsc: '',
          selectedCntr: []
        }
      )
    },
    // 행 삭제
    removeEmanInfo (idx) {
      const listLen = this.params.emanList.length
      let cnt = 1

      if (listLen === 1) {
        this.params.emanList[0].pkgQty = ''
        this.params.emanList[0].pkgCd = ''
        this.params.emanList[0].netWt = ''
        this.params.emanList[0].netCbm = ''
        this.params.emanList[0].imdunNo = ''
        this.params.emanList[0].cntrNo = ''
        this.params.emanList[0].gdsDsc = ''
        this.params.emanList[0].mrkDtlSeq = ''
        this.params.emanList[0].mrkSeq = ''
        this.params.emanList[0].mrkDsc = ''
        this.params.emanList[0].selectedCntr = []

        this.fnSubTotal()
        return
      }

      this.params.emanList.splice(idx, 1)
      this.params.emanList.forEach(element => {
        element.seq = cnt++
      })

      this.fnSubTotal()
    },
    fnSubTotal () {
      if (this.params.emanList === undefined || this.params.emanList.length === 0) {
        return
      }

      this.params.sumQty = this.params.emanList.reduce((sum, currValue) => {
        return this.fnDecimal(sum + Number(currValue.pkgQty))
      }, 0)

      this.params.sumWt = this.params.emanList.reduce((sum, currValue) => {
        return this.fnDecimal(sum + Number(currValue.netWt))
      }, 0)

      this.params.sumCbm = this.params.emanList.reduce((sum, currValue) => {
        return this.fnDecimal(sum + Number(currValue.netCbm))
      }, 0)

      this.params.sumQty = String(this.params.sumQty).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.params.sumWt = String(this.params.sumWt).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.params.sumCbm = String(this.params.sumCbm).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    fnDecimal (num) {
      return Math.round(num * 10000) / 10000
    },
    // Eman 입력항목 validation check
    async fnValidationEman (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isEmanOk = true

      this.params.emanList.forEach(eman => {
        // 'Quantity' 체크
        if (!this.fnValidationQuantity(eman.seq, frm)) {
          isEmanOk = false
        }
        // 'PKG Code' 체크
        if (!this.fnValidationPkgCd(eman.seq, frm)) {
          isEmanOk = false
        }
        // 'Weight' 체크
        if (!this.fnValidationWeight(eman.seq, frm)) {
          isEmanOk = false
        }
        // 'CBM' 체크
        if (!this.fnValidationCBM(eman.seq, frm)) {
          isEmanOk = false
        }
        // 'Item(Commodity)' 체크
        if (!this.fnValidationCmdt(eman.seq, frm)) {
          isEmanOk = false
        }
        // 'Container No.' 체크
        if (!this.fnValidationContainer(eman.seq, frm)) {
          isEmanOk = false
        }
      })

      const sumQty = Number(String(this.params.sumQty).replace(/,/g, ''))
      const sumWt = Number(String(this.params.sumWt).replace(/,/g, ''))
      const sumCbm = Number(String(this.params.sumCbm).replace(/,/g, ''))

      if (sumQty !== Number(this.parentInfo.pkgQty)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.021'))
        return false
      }

      if (sumWt !== Number(this.parentInfo.grsWt)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.021'))
        return false
      }

      if (sumCbm !== Number(this.parentInfo.grsCbm)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.021'))
        return false
      }

      if (this.parentInfo.polCtrCd === 'KR') {
        if (!await this.compareWithCtrInfo()) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: '컨테이너 입력란의 중량,수량과 동일하게\n컨테이너별로 중량,수량,CBM을 각각 입력하시기 바랍니다.' })
          return false
        }
      }

      return isEmanOk
    },
    fnValidationQuantity (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Quantity' 체크
      elem = frm.querySelector('#qty_' + (seq - 1))
      if (this.$ekmtcCommon.isEmpty(this.params.emanList[seq - 1].pkgQty)) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.017') // Package수량 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationPkgCd (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'PKG Code' 체크
      elem = frm.querySelector('#pkgCd_' + (seq - 1))
      if (this.$ekmtcCommon.isEmpty(this.params.emanList[seq - 1].pkgCd)) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.018') // Package단위 선택
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationWeight (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Weight' 체크
      elem = frm.querySelector('#wt_' + (seq - 1))
      if (this.$ekmtcCommon.isEmpty(this.params.emanList[seq - 1].netWt)) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.019') // Gross Weight 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationCBM (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'CBM' 체크
      elem = frm.querySelector('#cbm_' + (seq - 1))
      if (this.$ekmtcCommon.isEmpty(this.params.emanList[seq - 1].netCbm)) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.020') // Measure 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationCmdt (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Item(Commodity)' 체크
      elem = frm.querySelector('#cmdt_' + (seq - 1))
      if (this.$ekmtcCommon.isEmpty(this.params.emanList[seq - 1].gdsDsc)) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.021') // Item(Commodity)를 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    fnValidationContainer (seq, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_eman')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'Container No.' 체크
      elem = frm.querySelector('#cntr_no_' + (seq - 1))
      if (this.params.emanList[seq - 1].selectedCntr.length === 0) {
        isOk = false
        msg = this.$t('msg.ONEX050P060.022') // Container No.를 선택하세요.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    selectCntrNo (seq) {
      const frm = document.querySelector('#frm_eman')
      const elemsChk = frm.querySelectorAll('input[name="conno_' + seq + '"]:checked')

      this.params.emanList[seq - 1].selectedCntr = []

      // console.log('selectCntrNo >>> before')
      // console.log(this.params.emanList[seq - 1].selectedCntr)

      elemsChk.forEach(elem => {
        this.params.emanList[seq - 1].selectedCntr.push(elem.value)
      })

      // console.log('selectCntrNo >>> after')
      // console.log(this.params.emanList[seq - 1].selectedCntr)
    },
    // ITEM(Commodity) 금지항목 체크
    async fnRtnCHRCheck (seq) {
      const frm = document.querySelector('#frm_eman')
      const elem = frm.querySelector('#cmdt_' + (seq - 1))
      let isOk = false

      if (this.$ekmtcCommon.isNotEmpty(this.params.emanList[seq - 1].gdsDsc)) {
        if (this.phbtList !== undefined && this.phbtList.length > 0) {
          for (const phbt of this.phbtList) {
            if (phbt.apclYn === 'Y') {
              if (this.params.emanList[seq - 1].gdsDsc.indexOf(phbt.phbtChr) > -1) {
                if (await this.$ekmtcCommon.asyncAlertMessage({ message: phbt.rmk })) {
                  isOk = true
                  elem.focus()
                  break
                }
              }
            }
            if (phbt.apclYn === 'A') {
              if (this.params.emanList[seq - 1].gdsDsc.indexOf(phbt.phbtChr) > -1) {
                if (!await this.$ekmtcCommon.asyncAlertMessage({ message: phbt.rmk, useConfirmBtn: true })) {
                  isOk = true
                  elem.focus()
                  break
                }
              }
            }
          }
        }
      }

      return isOk
    },
    async fnCheckEmpty (eman) {
      let isNotEmptyCnt = 0
      for (const selCntr of eman.selectedCntr) {
        for (const cntr of this.cntrList) {
          if (cntr.cntrNo === selCntr) {
            if (cntr.feUnitCd !== 'E') {
              isNotEmptyCnt++
            }
          }
        }
      }
      return !(isNotEmptyCnt > 0)
    },
    /* 24.09.03 SHA M/F Data 컨테이너별 중량,수량,CBM 유효성검사 추가 */
    async compareWithCtrInfo () {
      const ctrList = this.parentInfo.containerList
      const emanList = this.params.emanList
      const filteredList = ctrList.filter((ctrItem) => {
        let cnt = 0
        emanList.forEach((emanItem) => {
          const condition = Number(ctrItem.wt) === Number(emanItem.netWt) && /* 1.중량비교 */
                            Number(ctrItem.pkgQty) === Number(emanItem.pkgQty) && /* 수량비교 */
                            emanItem.selectedCntr.includes(ctrItem.cntrNo) /* 컨테이너 넘버 비교 */
          if (condition) {
            cnt++
          }
        })
        return cnt > 0
      })
      return ctrList.length === filteredList.length
    },
    // 정보 저장
    async fnEmanSave () {
      if (await this.fnValidationEman()) {
        let isChrOk = true
        let frm = document.querySelector('#frm_eman')
        let elem

        for (const eman of this.params.emanList) {
          if (await this.fnRtnCHRCheck(eman.seq)) {
            isChrOk = false
            break
          } else if (Number(eman.pkgQty) <= 0 || Number(eman.netWt) <= 0) {
            if (await this.fnCheckEmpty(eman) === true) {
              // EMPTY 체크가 되어 있기 때문에 입력 가능
            } else {
              isChrOk = false
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX050P060.025') })) { // 중량, 수량은 0보다 초과로 입력되어야 합니다.
                if (Number(eman.pkgQty) <= 0) {
                  elem = frm.querySelector('#qty_' + (eman.seq - 1))
                } else {
                  elem = frm.querySelector('#wt_' + (eman.seq - 1))
                }
                elem.focus()
                break
              }
            }
          }
        }

        if (!isChrOk) return

        // console.log(this.params)
        // console.log('this.params')

        await shaMfData.popEmanUpload(this.params)
          .then((response) => {
            if (response.headers.respcode === 'C0000') {
              const TH = this

              // Eman을 등록하였습니다.
              TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONEX050P060.023'), () => {
                const obj = {
                  sumQty: this.params.sumQty,
                  sumWt: this.params.sumWt,
                  sumCbm: this.params.sumCbm,
                  path: this.params.path
                }
                TH.$emit('selectFunc', obj)
                TH.$emit('close')
              })
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P060.024')) // Eman을 등록에 실패하였습니다. 관리자에게 문의해 주세요.
            }
          })
      }
    }
  }
}
</script>
